<template>
  <div>
    <loader v-if="isLoading || !currentObject" />
    <div
      v-else
      class="info flex justify-between"
      :key="currentObject.id"
    >
      <div class="info__description">
        <router-link
          :to="'/'"
          class="inline-flex align-center rocky--text"
          event
          @click.native.prevent="routeBack"
        >
          <r-icon
            icon="arrow-left"
            class="mr-1"
          />
          <div>Назад</div>
        </router-link>
        <div class="flex align-center mt-6 mb-2">
          <h1 class="taleggio">
            {{ !isDistrict ? currentObject.name : `Участок №${district.district}` }}
          </h1>
          <div :key="count">
            <r-icon
              @click.native.prevent="operateFavorite(currentObject.id, favoriteIds[currentObject.id], currentObject.name)"
              class="ml-3 pointer"
              :icon="!favoriteIds[currentObject.id] ? 'favorite-add' : 'favorite-add-active'"
              size="20"
              fill="rocky"
            />
          </div>
          <!-- <rir-icon class="ml-3" icon="favorite-add" size="20" /> -->
        </div>
        <p class="info__address feta mt-1 mb-3">
          {{ currentObject.address }}
        </p>
        <a
          :href="`/create-route?toCoords=${coordinates}`"
          class="flex align-center mt-4 briscola rocky--text pointer"
        >
          <r-icon
            icon="geopoint"
            fill="rocky"
            class="mr-2"
            size="16"
          />
          Построить маршрут
        </a>
        <div
          class="info__mob-map-cont"
          ref="mobMapCont"
        />

        <div
          :class="['flex', 'flex-col', { 'flex-col-reverse': !isDistrict }]"
          class="open_image mb-8 rounded-16"
          @click="openModalImage($event,{src: currentObject.img, name: currentObject.name})"
        >
          <img
            v-show="currentObject.img"
            class="info__img rounded-16"
            :src="currentObject.img"
            :alt="currentObject.name"
          >
          <r-icon
            icon="search"
            class="r-icon"
            size="56"
            fill="amelie"
          />
        </div>

        <template v-if="!isDistrict">
          <p class="feta opacity-48">
            Полное название
          </p>
          <p class="parmigiano mt-2">
            {{ currentObject.fullName }}
          </p>

          <text-accordion class="mt-8">
            <template #default>
              <p
                class="parmigiano"
                v-html="description"
              />
            </template>
          </text-accordion>
        </template>

        <div :class="['info__contacts', 'rocky--text', { 'mt-8': !isDistrict }]">
          <contact-list :contacts="contacts" />
        </div>

        <div
          v-if="workHours"
          class="info__work-hours parmigiano mt-8 text-left flex align-start"
        >
          <r-icon
            class="mr-2 mt-1"
            icon="clock"
            fill="rocky"
          />
          <div class="mt-2px">
            {{ workHours }}
          </div>
        </div>
        <template v-if="!isInstitution">
          <p class="info__address med mt-8 mb-2">
            Закреплен за медучреждением
          </p>
          <router-link
            v-if="isDistrict"
            class="info__institution-link"
            :to="{ name: 'info', query: { id: currentObject.id, type: 'institution' } }"
          >
            <p class="info__name tulum">
              {{ currentObject.name }}
            </p>
            <!-- <a class="flex align-center mt-2 mb-9 sulguni text-rocky" href="#"
              ><rir-icon class="mr-3" icon="geopoint" />Построить маршрут</a
            > -->
          </router-link>

          <div v-else>
            <p
              class="info__address feta mt-1"
              v-if="currentObject.address"
            >
              {{ currentObject.address }}
            </p>
            <!-- <a class="flex align-center mt-2 mb-8 sulguni text-rocky" href="#"
              ><rir-icon class="mr-3" icon="geopoint" />Построить маршрут</a
            > -->
          </div>
          <div
            class="mt-8"
            v-if="district.addresses"
          >
            <a
              class="ext-link"
              @click="openModalAddress"
            >Обслуживаемые адреса</a>
          </div>
        </template>

        <div
          class="mt-8 sulguni flex flex-column align-start"
          v-if="!isDistrict"
        >
          <a
            class="ext-link"
            :href="isValidHttpUrl(currentObject.webEmias) ? currentObject.webEmias : 'https://'+currentObject.webEmias"
            target="_blank"
            rel="nofollow"
          >Записаться на прием</a>
          <a
            class="ext-link mt-1"
            :href="isValidHttpUrl(currentObject.webSchedule) ? currentObject.webSchedule : 'https://'+currentObject.webSchedule"
            target="_blank"
            rel="nofollow"
          >Расписание работы врачей</a>
        </div>

        <div
          v-show="transportStopList.length"
          class="info__transport mt-8"
        >
          <span class="info__transport-title feta"> Ближайшие остановки </span>
          <stops-list :list="transportStopList" />
        </div>

        <!--        <button class="info__show-on-map sulguni">-->
        <!--          <rir-icon class="mr-2" icon="location" />-->
        <!--          Показать на карте-->
        <!--        </button>-->

        <div
          v-if="!isDistrict && currentObject.contacts.length"
          class="mt-8"
        >
          <h3 class="tallegio mb-5">
            Контакты
          </h3>

          <div
            class="mb-8 flex flex-column align-start"
            v-for="(contact, index) in currentObject.contacts"
            :key="index"
          >
            <div class="flex">
              <div
                class="open_image rounded-full mr-2"
                @click="openModalImage($event,{src: contact.img, name: contact.name})"
              >
                <img
                  v-if="contact.img"
                  class="object-cover"
                  :src="contact.img"
                  :alt="`Фото ${contact.name}`"
                  width="56"
                  height="56"
                >
                <r-icon
                  icon="search"
                  size="16"
                  fill="amelie"
                  class="r-icon__search"
                />
              </div>

              <div>
                <p class="bryndza">
                  {{ contact.name }}
                </p>
                <p class="mt-1 feta opacity-72">
                  {{ contact.position }}
                </p>
              </div>
            </div>
            <contact-list
              class="mt-4 rocky--text"
              :contacts="makeStaffContacts(contact)"
            />
            <!-- <a
              v-if="contact.type === 'staff'"
              class="sulguni ext-link mt-2"
              :href="currentObject.webEmias"
              >Записаться на прием</a
            > -->
          </div>
        </div>

        <!-- Врачи (учреждения) -->
        <div
          v-if="isDistrict"
          class="mt-8"
        >
          <h3 class="tallegio mb-5">
            Врачи
          </h3>
          <div
            class="flex flex-column align-start mb-8"
            v-for="staff in staff"
            :key="staff.id"
          >
            <div class="flex">
              <div
                class="open_image rounded-full mr-2"
                v-if="staff.img"
                @click="openModalImage($event,{src: staff.img, name: staff.name})"
              >
                <img
                  class="object-cover"
                  :src="staff.img"
                  :alt="staff.name"
                  width="56"
                  height="56"
                >
                <r-icon
                  icon="search"
                  size="16"
                  fill="amelie"
                  class="r-icon__search"
                />
              </div>
              <div
                v-else
                class="flex align-center justify-center mr-4 arrival rounded-full"
                style="height: 56px; width: 56px;align-items: center;"
              >
                <r-icon
                  icon="avatar"
                  size="20"
                  fill="rocky"
                />
              </div>
              <div>
                <p class="bryndza">
                  {{ staff.name }}
                </p>
                <p class="mt-1 feta opacity-72">
                  {{ staff.position }}
                </p>
              </div>
            </div>

            <a
              class="sulguni ext-link mt-4"
              :href="isValidHttpUrl(currentObject.webEmias) ? currentObject.webEmias : 'https://'+currentObject.webEmias"
              target="_blank"
              rel="nofollow"
            >Записаться на прием</a>
          </div>
        </div>
        <!-- ~~~ -->

        <div
          v-if="!isDistrict && currentObject.districts && currentObject.districts.length"
          class="mt-8"
        >
          <h3 class="tallegio mb-5">
            Участки
          </h3>

          <router-link
            v-for="district in currentObject.districts"
            :key="district.id"
            :to="{ name: 'info', query: { id: district.id, type: 'district' } }"
            class="bryndza"
          >
            <div class="mb-8 flex align-center">
              <div
                class="arrival rounded-full mr-4 flex align-center justify-center overflow-hidden"
                style="height: 56px; width: 56px;align-items: center;"
              >
                <div
                  class="open_image"
                  v-if="currentObject.img"
                  style="width:100%;height:100%;"
                  @click="openModalImage($event,{src: currentObject.img, name: district.name})"
                >
                  <img
                    class="object-cover"
                    style="width:100%;height:100%;"
                    :src="currentObject.img"
                    :alt="`Участок № ${district.name}`"
                  >
                  <r-icon
                    icon="search"
                    size="16"
                    fill="amelie"
                    class="r-icon__search"
                  />
                </div>
                <r-icon
                  v-else
                  icon="mayor"
                  size="20"
                  fill="rocky"
                />
              </div>
              {{ `Участок № ${district.name}` }}
            </div>
          </router-link>
        </div>

        <!-- На бэке возможна ценка только одной сущности (учреждение / участок)  -->
        <rating
          v-if="isDistrict"
          :title="isDistrict ? 'Оцените работу участка' : 'Оцените учреждение'"
          :val="
            district
              ? district.rating
                ? district.rating.substr(0, 3)
                : null
              : currentObject.rating
                ? currentObject.rating.substr(0, 3)
                : null
          "
          @change="onRateObject"
        />
      </div>

      <div
        class="info__pc-map-cont"
        ref="pcMapCont"
      >
        <div
          :class="['info__map', { 'info__map--collapsed': isMapCollapsed }]"
          ref="map"
        >
          <rir-map
            v-if="isShowMap && currentObject"
            :center="currentObject.lat != undefined ? [Number(currentObject.lat), Number(currentObject.lng)] : null"
          >
            <ymap-marker
              v-if="currentObject.lat != undefined"
              :coords="[Number(currentObject.lat), Number(currentObject.lng)]"
              :marker-id="'clinic_' + currentObject.id"
              :icon="$markerIcon()"
            />

            <div v-if="district && district.geometry.length > 0">
              <div
                v-if="countArrays(district.geometry) >= 4"
                v-for="(pol, index) in district.geometry"
                :key="index"
              >
                <ymap-marker
                  marker-type="Polygon"
                  v-if="Array.isArray(pol)"
                  :coords="pol"
                  :marker-id="'district_' + index + '_' + district.id"
                  :options="{ hideIconOnBalloonOpen: false }"
                  :hint-content="`Участок №${district.district}`"
                  :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                  :marker-stroke="{ color: '#3d75e4', width: 1 }"
                />
              </div>
              <div
                v-if="countArrays(district.geometry) == 3"
                v-for="(pol, index) in [district.geometry]"
                :key="index"
              >
                <ymap-marker
                  marker-type="Polygon"
                  v-if="Array.isArray(pol)"
                  :coords="pol"
                  :marker-id="'district_' + index + '_' + district.id"
                  :options="{ hideIconOnBalloonOpen: false }"
                  :hint-content="`Участок №${district.district}`"
                  :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                  :marker-stroke="{ color: '#3d75e4', width: 1 }"
                />
              </div>
            </div>
          </rir-map>
          <div
            class="info__map-collapse-btn feta"
            @click="isMapCollapsed = !isMapCollapsed"
          >
            <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
          </div>
        </div>
      </div>
    </div>

    <r-modal
      ref="modal"
      close-icon
    />
    <r-modal
      ref="modalImage"
      :fullscreen="true"
      close-icon
    />
  </div>
</template>

<script>
import ModalAddress from '@/components/ModalAddress';
import Loader from '@/components/Loader.vue';
import Api from '../api/Api';
import TextAccordion from '../components/TextAccordion.vue';
import StopsList from '../components/StopsList.vue';
import ContactList from '../components/ContactList.vue';
import RirMap from '../components/RirMap.vue';
import Rating from '../components/Rating.vue';

import ModalGallery from '../components/ModalGallery.vue';
import ModalImage from '../components/ModalImage';

export default {
  name: 'MedicineInfo',
  components: {
    Loader,
    TextAccordion,
    StopsList,
    ContactList,
    RirMap,
    Rating
  },

  // watch: {
  //   currentObject() {
  //     this.$nextTick(() => {
  //       this.onWindowResize();
  //       this.selectedRating = 0;
  //     });
  //     this.getNearbyTransport();
  //   }
  // },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.$store.dispatch('selectObject', vm.$route.params.id);
  //     vm.isFromMainPage = from.matched?.length > 0;
  //     window.scrollTo(0, 0);
  //   });
  // }

  beforeRouteEnter(to, from, next) {
    console.log('from', from);
    const { name, path, query } = from;
    next(vm => {
      vm.prevRoute = { name, path, query };
    });
  },

  beforeRouteUpdate(to, from, next) {
    console.log('leave', to, from.name);
    const { name, path, query } = from;
    this.prevRoute = { name, path, query };
    next();
  },
  data() {
    return {
      isShowMap: false,
      isMapCollapsed: true,
      isMobile: false,
      transportStopList: [],
      isFromMainPage: false,
      prevRoute: {},
      favorite: [],
      favoriteIds: [],
      count: 0
    };
  },

  computed: {
    isLoading() {
      return this.$store.state.isObjectLoading;
    },
    isDistrict() {
      return this.$route.query.type === 'district';
    },
    isInstitution() {
      return this.$route.query.type === 'institution';
    },
    coordinates() {
      return [this.currentObject.lat, this.currentObject.lng] || null;
    },
    description() {
      return (
        this.currentObject.description && this.currentObject.description.split('\n').join('<br>')
      );
    },
    district() {
      const district = this.isDistrict
        ? this.$store.getters.getDistrictById(this.$route.query.id)
        : null;
      console.log('districtA', district);
      return district;
    },
    staff() {
      if (!this.isDistrict) return [];
      const staff = this.district?.staff?.length > 0 ? this.district.staff : [];
      return staff.map(staffId => {
        const { id, name, position, img } = this.$store.getters.getStaffById(staffId);
        return { id, name, position, img };
      });
    },
    currentObject() {
      const id = this.district?.institutionId ? this.district.institutionId : this.$route.query.id;
      let obj = this.$store.getters.getInstitutionById(id);

      if (obj === undefined) {
        obj = [];
      } else if (obj?.lat && obj?.lng) this.getNearbyTransport(obj.lat, obj.lng);
      if (id) {
        this.favoriteIds[id] = false;
        if (this.favorite.fav != undefined) {
          const fav = this.favorite.fav.filter(el => id == el.id);
          if (fav.length > 0) {
            this.favoriteIds[id] = true;
          }
        }
      }

      return obj;
    },

    workHours() {
      return this.currentObject.hours || null;
    },

    contacts() {
      const contacts = [];
      const { phones } = this.currentObject;
      const { emails } = this.currentObject;
      const { web } = this.currentObject;
      if (phones?.length) {
        contacts.push({
          icon: 'telephone',
          contacts: phones.map(phone => ({
            title: phone,
            href: `tel:${phone.replace(/[^0-9+.]/g, '')}`
          }))
        });
      }

      if (emails?.length) {
        contacts.push({
          icon: 'email',
          contacts: emails.map(email => ({
            title: email,
            href: `mailto:${email}`
          }))
        });
      }

      if (web) {
        contacts.push({
          icon: 'link',
          contacts: [
            {
              title: web,
              href: `http://${web}`
            }
          ]
        });
      }

      return contacts;
    }
    // prevRoutePath() {
    //   return this.prevRoute ? { name: this.prevRoute.name, query: this.prevRoute.query } : '/';
    // },
  },
  async mounted() {
    !this.$store.state.isHasObjects && this.$store.dispatch('loadObjects');
    const api = new Api();
    this.favorite = await api.getFavorite();
  },
  activated() {
    this.isShowMap = true;
    window.addEventListener('resize', this.onWindowResize);
  },
  deactivated() {
    this.isShowMap = false;
    window.removeEventListener('resize', this.onWindowResize);
  },
  updated() {
    console.log(11);
    this.onWindowResize();
  },
  methods: {
    operateFavorite(id, value, title) {
      const api = new Api();
      api.setFavorite(id, title);
      this.favoriteIds[id] = !value;
      this.count++;
    },
    countArrays(array) {
      let sumTotal = 1;

      for (const element of array) {
        if (Array.isArray(element)) {
          sumTotal += this.countArrays(element);
        }
      }

      return sumTotal;
    },
    isValidHttpUrl(string) {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:';
    },
    onRateObject(rating) {
      // Номер категории в меню старого сайта...
      const currentMainMenuNumber = '6';
      const payload = JSON.stringify({
        id: +this.$route.query.id,
        currentMainMenuNumber,
        rating
      });
      new Api().setRating(payload);
    },
    makeStaffContacts(item) {
      const contacts = [];

      item.phoneList.length
      && contacts.push({
        icon: 'telephone',
        contacts: item.phoneList.map(phone => ({
          title: phone,
          href: `tel:${phone}`
        }))
      });

      item.mailList.length
      && contacts.push({
        icon: 'email',
        contacts: item.mailList.map(email => ({
          title: email,
          href: `mailto:${email}`
        }))
      });

      return contacts;
    },
    routeBack() {
      // console.log(this.$route, this.$router);
      // console.log(this.$router.go(-1));
      // return;
      // history.back()
      this.prevRoute.name ? this.$router.go(-1) : this.$router.push('/');
    },
    async openModalGallery(index) {
      await this.$refs.modal.openModal(ModalGallery, {
        items: this.galleryImages,
        index
      });
    },
    async openModalImage(e, image) {
      e.preventDefault();
      await this.$refs.modalImage.openModal(ModalImage, {
        img: image.src,
        name: image.name
      });
    },

    async openModalAddress() {
      console.log(this.district);
      await this.$refs.modal.openModal(
        ModalAddress,
        {
          district: this.district
        },
        {}
      );
    },
    onWindowResize() {
      if (this.isLoading || !this.$refs.pcMapCont) return;
      const width = window.innerWidth;
      const { map } = this.$refs;
      if (width > 1023) {
        !this.$refs.pcMapCont?.hasChildNodes() && this.$refs.pcMapCont.appendChild(map);
        this.isMobile = false;
      } else {
        !this.$refs.mobMapCont.hasChildNodes() && this.$refs.mobMapCont.appendChild(map);
        this.isMobile = true;
      }
    },
    async getNearbyTransport(lat, lng) {
      const params = {
        lat,
        lng
      };
      const res = await new Api().getTransport(params);

      if (!Array.isArray(res) || !res?.length) return;

      this.transportStopList = [...res]
        .sort((a, b) => a.d - b.d)
        .map(el => ({
          name: el.name,
          routes: [
            ...Object.keys(el.routes).map(route => ({
              route,
              type: el.routes[route]?.vehicleTypes[0] || null
            }))
          ]
        }))
        .splice(0, 2);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .rir-modal__content-rir-icon_close {
  z-index: 1002;
}

.r-icon__search {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -40%);
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in-out;
}
.open_image {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  //width: 100%;

  &:before {
    content: '';
    background: linear-gradient(360deg, rgba(4, 21, 62, 0.8) 0%, rgba(4, 21, 62, 0) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
  }

  .r-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out;
  }
  &:hover .r-icon {
    transition: 0.5s;
    opacity: 1;
  }
  &:hover .r-icon__search {
    transition: 0.5s;
    opacity: 1;
  }
  &:hover {
    &:before {
      opacity: 1;
    }

    .rir-icon {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
}

.med {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153e;
  opacity: 0.48;
}

.info {
  &__name {
    text-decoration: underline;
  }

  &__description {
    text-align: left;
    width: 41.7%;
  }

  &__gallery {
    height: 234px;
  }

  &__work-hours {
    color: var(--rir-titanic);
  }

  &__transport-title {
    opacity: 0.48;
  }

  &__pc-map-cont {
    padding-left: 32px;
    width: 58.3%;
  }

  &__img {
    aspect-ratio: 16/10;
    object-fit: cover;
    // height: 234px;
    // width: 375px;
    width: 100%;
  }

  &__mob-map-cont {
    display: none;
  }

  &__map {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    max-width: 100%;
    width: 100%;
    height: 648px;
    background-color: #ccc;
    border-radius: 24px;
    transition: 0.4s all;
  }

  &__show-on-map {
    display: flex;
    align-items: center;
    background: none;
    margin-top: 18px;
    color: var(--rir-rocky);

    &:hover {
      cursor: pointer;
    }
  }

  &__map-collapse-btn {
    height: 40px;
    width: 174px;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 87px);
    background-color: var(--rir-arrival);
    cursor: pointer;
    display: none;

    :first-child {
      color: var(--rir-godfather);
      border-bottom: 2px dotted #214eb052;
    }
  }

  &__institution-link {
    &:hover {
      * {
        color: var(--rir-godfather);
      }
    }
  }
}

.ext-link {
  border-bottom: 2px solid var(--rir-alien);
}

::v-deep .rir-modal__content {
  display: grid;
  align-items: center;
}

@media screen and (max-width: 1023px) {
  .info {
    &__description {
      width: 100%;
    }

    &__map {
      position: relative;
      top: unset;
      margin-bottom: 44px;
      height: 447.31px;
    }

    &__map--collapsed {
      height: 112px;
    }

    &__mob-map-cont {
      display: block;
    }

    &__pc-map-cont {
      display: none;
    }

    &__map-collapse-btn {
      display: block;
    }
  }
}

@media screen and (max-width: 599px) {
  .info {
    &__map--collapsed {
      height: 144px;
    }
  }
}
.kurza, .rounded-16 {
  border-radius: 16px!important;
}
.rounded-full {
  border-radius: 9999px;
}
.arrival {
  background-color: #e4edfb!important;
}
.align-start {
  align-items: flex-start;
}
.flex-column {
  flex-direction: column;
}
</style>
