<template>
  <div class="m-addresses">
    <div class="mb-8 camembert">
      Обслуживаемые адреса
    </div>
    <div class="m-addresses__list parmigiano">
      {{ district.addresses }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    district: {
      type: String,
      default: null
    }
  },
  computed: {
    addresses() {
      return this.district.addresses;
    }
  }
};
</script>
<style lang="scss" scoped>
.m-addresses {
  display: flex;
  flex-direction: column;
  align-self: self-start;
  &__list {
    white-space: pre-line;
  }
}
</style>
