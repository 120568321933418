<template>
  <div>
    <tag-carousel
      :items="objectTypes"
      @select="onChangeType"
    />
    <div class="medicine mt-8">
      <div class="medicine__cards">
        <div
          v-for="item in objects"
          :key="item.id"
          class="flex"
        >
          <router-link
            class="size"
            :key="item.id"
            :to="{
              name: 'info',
              query: { id: item.id, type: selectedCategory === 0 ? 'institution' : 'district' }
            }"
          >
            <object-card
              :name="selectedCategory === 0 ? item.name : 'Участок №' + item.district"
              :address="item.address"
              :img="getPreviewImg(item)"
              :selected-category="selectedCategory"
            >
              <div
                class="flex"
                v-if="item.phones"
              >
                <r-icon
                  class="mr-2"
                  icon="telephone"
                  fill="rocky"
                />
                <span
                  class="briscola mr-4 rocky--text"
                  v-for="(phone, index) in item.phones"
                  :key="index"
                >{{ phone }}</span>
              </div>
            </object-card>
          </router-link>
          <div
            :key="count"
            @click="operateFavoriteCard(item.id, item.name)"
          >
            <r-tooltip

              :title="!favoriteIds[item.id] ? 'в избранное' : 'Удалить из избранного'"
              position="top-center"
              align-title="end"
              max-width="200px"
            >
              <template #activator>
                <r-icon
                  class="mt-1 ml-auto pointer"
                  :icon="!favoriteIds[item.id] ? 'favorite-add' : 'favorite-add-active'"
                  size="16"
                  fill="rocky"
                />
              </template>
            </r-tooltip>
          </div>
        </div>
      </div>
      <div class="medicine__map-cont">
        <div
          :class="['medicine__map', { 'medicine__map--collapsed': isMapCollapsed }]"
          ref="map"
        >
          <rir-map
            v-if="showMap"
            v-model="search"
            search
            :center="personAddressCoords.length ? personAddressCoords : null"
          >
            <!-- Адрес жителя -->
            <div v-if="searchText && selectedCategory !== 0 && personAddressCoords.length">
              <ymap-marker
                :key="`person_${searchText}`"
                :coords="personAddressCoords"
                :hint-content="personAddressHint"
                :marker-id="`person_address`"
                :icon="$markerIcon()"
              >
                <!-- <BalloonCard
                slot="balloon"
                :title="marker.name"
                :address="marker.address"
                :img="marker.img"
                :data-id="`marker_${marker.id}`"
              >
                <div class="flex mt-3 justify-space-between">
                  <rir-button class="opacity-72" data-action="copy" transparent contentWidth>
                    <rir-icon class="mr-2" icon="copy" />
                    <span class="briscola text-rocky">Дублировать</span>
                  </rir-button>
                  <rir-button transparent contentWidth data-action="delete">
                    <rir-icon class="opacity-72" icon="delete" fill="fargo" />
                  </rir-button>
                </div>
              </BalloonCard> -->
              </ymap-marker>
            </div>

            <div v-if="isShowMap && selectedCategory === 0">
              <ymap-marker
                v-for="marker in objects"
                :key="`institution_${marker.id}`"
                :coords="[`${marker.lat || 0}`, `${marker.lng || 0}`]"
                :marker-id="marker.id"
                :icon="$markerIcon()"
                :options="{ hideIconOnBalloonOpen: false }"
                cluster-name="main"
                @click="onMarkerClick"
                @balloonopen="onBalloonOpen($event, marker.id, favoriteIds[marker.id], marker.name)"
                @balloonclose="onBalloonClose()"
              >
                <balloon-card
                  slot="balloon"
                  :title="marker.name"
                  :address="marker.address"
                  :img="marker.img"
                  :id="marker.id"
                  :favorite="favoriteIds[marker.id]"
                  :info="true"
                >
                  <div />
                  <!-- <div class="flex mt-3">
                    <rir-icon class="mr-2" icon="geopoint" />
                    <span class="briscola text-rocky">Построить маршрут</span>
                  </div> -->
                </balloon-card>
              </ymap-marker>
            </div>

            <div
              v-else
              :key="`district_${district.id}`"
              v-for="district in objects"
            >
              <div
                v-if="countArrays(district.geometry) >= 4"
                v-for="(pol, index) in district.geometry"
                :key="index"
              >
                <div
                  v-for="(pol, index) in district.geometry"
                  :key="`${district.id}_${index}`"
                >
                  <ymap-marker
                    v-if="Array.isArray(pol)"
                    marker-type="Polygon"
                    :coords="pol"
                    :marker-id="`${district.id}_polygon_${index}`"
                    :options="{ hideIconOnBalloonOpen: false }"
                    :hint-content="`Участок №${district.district}`"
                    :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                    :marker-stroke="{ color: '#3d75e4', width: 1 }"
                    @click="onMarkerClick"
                    @balloonopen="onBalloonOpen($event, district.id, favoriteIds[district.id], district.name)"
                    @balloonclose="onBalloonClose()"
                  >
                    <balloon-card
                      slot="balloon"
                      :id="district.id"
                      :favorite="favoriteIds[district.id]"
                    >
                      <div class="flex">
                        <span class="caprino opacity-48 mt-1">Участок №{{ district.district }}</span>
                        <div class="ml-auto">
                          <div
                            :link-id="district.id"
                            class="link-balloon"
                          />
                        </div>
                      </div>
                      <div
                        class="mt-3 balloon-staff"
                        v-for="staff in getStaffBaloon(district.staff)"
                        :key="staff.id"
                      >
                        <img
                          v-if="staff.img"
                          class="rounded-full"
                          :src="staff.img"
                          alt=""
                          width="40"
                          height="40"
                        >
                        <div
                          v-else
                          class="arrival flex align-center justify-center rounded-full"
                          style="width: 40px; height: 40px"
                        >
                          <r-icon
                            class="opacity-48"
                            icon="avatar"
                            fill="rocky"
                          />
                        </div>
                        <div class="ml-2 overflow-hidden">
                          <p class="roquefort truncate">
                            {{ staff.name }}
                          </p>
                          <p class="mt-1 caprino opacity-72 truncate">
                            {{ staff.position }}
                          </p>
                        </div>
                      </div>
                      <p class="mt-3 caprino opacity-72 mb-3">
                        {{ district.address }}
                      </p>
                    </balloon-card>
                  </ymap-marker>
                </div>
              </div>
              <div
                v-if="countArrays(district.geometry) == 3"
                v-for="(pol, index) in [district.geometry]"
                :key="index"
              >
                <div
                  v-for="(pol, index) in [district.geometry]"
                  :key="`${district.id}_${index}`"
                >
                  <ymap-marker
                    v-if="Array.isArray(pol)"
                    marker-type="Polygon"
                    :coords="pol"
                    :marker-id="`${district.id}_polygon_${index}`"
                    :options="{ hideIconOnBalloonOpen: false }"
                    :hint-content="`Участок №${district.district}`"
                    :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                    :marker-stroke="{ color: '#3d75e4', width: 1 }"
                    @click="onMarkerClick"
                    @balloonopen="onBalloonOpen($event, district.id, favoriteIds[district.id], district.name)"
                    @balloonclose="onBalloonClose()"
                  >
                    <balloon-card
                      slot="balloon"
                      :id="district.id"
                      :favorite="favoriteIds[district.id]"
                    >
                      <div class="flex">
                        <span class="caprino opacity-48 mt-1">Участок №{{ district.district }}</span>
                        <div class="ml-auto">
                          <div
                            :link-id="district.id"
                            class="link-balloon"
                          />
                        </div>
                      </div>
                      <div
                        class="mt-3 balloon-staff"
                        v-for="staff in getStaffBaloon(district.staff)"
                        :key="staff.id"
                      >
                        <img
                          v-if="staff.img"
                          class="rounded-full"
                          :src="staff.img"
                          alt=""
                          width="40"
                          height="40"
                        >
                        <div
                          v-else
                          class="arrival flex align-center justify-center rounded-full"
                          style="width: 40px; height: 40px"
                        >
                          <r-icon
                            class="opacity-48"
                            icon="avatar"
                            fill="rocky"
                          />
                        </div>
                        <div class="ml-2 overflow-hidden">
                          <p class="roquefort truncate">
                            {{ staff.name }}
                          </p>
                          <p class="mt-1 caprino opacity-72 truncate">
                            {{ staff.position }}
                          </p>
                        </div>
                      </div>
                      <p class="mt-3 caprino opacity-72 mb-3">
                        {{ district.address }}
                      </p>
                    </balloon-card>
                  </ymap-marker>
                </div>
              </div>
            </div>
          </rir-map>
          <!-- <loading-content v-else /> -->
          <button
            class="medicine__map-collapse-btn feta"
            @click="isMapCollapsed = !isMapCollapsed"
          >
            <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ObjectCard from '@/components/ObjectCard.vue';
import TagCarousel from '../components/TagCarousel.vue';
import RirMap from '../components/RirMap.vue';
import BalloonCard from '../components/BalloonCard.vue';
import Api from '../api/Api.js';

export default {
  name: 'MedicineList',
  components: {
    ObjectCard,
    TagCarousel,
    RirMap,
    BalloonCard
  },

  data() {
    return {
      objectTypes: [
        {
          id: 3,
          title: 'Участковые терапевты',
          active: true
        },
        {
          id: 2,
          title: 'Участковые педиатры',
          active: false
        },
        {
          id: 0,
          title: 'Учреждения',
          active: false
        }
      ],

      files: [],
      selectedCategory: 3,
      isMapCollapsed: false,
      searchText: null,
      timeout: null,
      currentMarkerId: null,
      showMap: false,
      personAddressCoords: [],
      personAddressHint: '',
      favorite: [],
      favoriteIds: [],
      favoriteCount: 0,
      count: 0,
      selectedMarkerTitle: '',
      eventId: null,
      onEvent: false
    };
  },

  computed: {
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
        }, 500);
      }
    },
    isShowMap() {
      return !this.isLoading && this?.objects?.length > 0;
    },

    objects() {
      const _this = this;
      let allObjects = [];
      if (this.selectedCategory === 0) {
        allObjects = this.$store.state.clinics.map(el => {
          _this.favoriteIds[el.id] = false;
          if (_this.favorite.fav != undefined) {
            const fav = _this.favorite.fav.filter(els => el.id == els.id);
            if (fav.length > 0) {
              _this.favoriteIds[el.id] = true;
            }
          }
          return {
            sort: _this.favoriteIds[el.id],
            ...el,
            phones: Array.isArray(el?.phones) && el.phones.length ? el.phones : null
          };
        });
      } else {
        // TODO: есть элементы с  id = null...
        allObjects = this.$store.state.districts && this.$store.state.districts.filter(el => el.id);
        if (!allObjects?.length) return [];

        allObjects = allObjects.map(el => {
          let phones = this.$store.getters.getInstitutionById(el.institutionId)?.phones;

          if (!Array.isArray(phones) || phones?.length < 1) {
            phones = null;
          }
          _this.favoriteIds[el.id] = false;
          if (_this.favorite.fav != undefined) {
            const fav = _this.favorite.fav.filter(els => el.id == els.id);
            if (fav.length > 0) {
              _this.favoriteIds[el.id] = true;
            }
          }
          return {
            sort: _this.favoriteIds[el.id],
            ...el,
            address: el?.clinics?.[0].address ? el.clinics[0].address : '',
            title: el?.clinics?.title ? el.clinics.title : '',
            name: `Участок №${el?.district}`,
            phones
          };
        });

        if (allObjects && this.selectedCategory !== '0') {
          allObjects = allObjects.filter(el => Number(el.type) === this.selectedCategory);
        }
      }

      // if (this.searchText) {
      //   allObjects = allObjects.filter(el =>
      //     el?.address
      //       ? el?.address.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
      //       : false
      //   );
      // }
      console.log(allObjects, this.selectedCategory);
      allObjects = allObjects.sort((a, b) => b.sort - a.sort);

      return allObjects;
    },

    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },

  watch: {
    searchText() {
      this.searchAddress();
    }
  },

  async mounted() {
    const api = new Api();
    !this.$store.state.isHasObjects && this.$store.dispatch('loadObjects');
    this.favorite = await api.getFavorite();
  },

  activated() {
    this.showMap = true;
  },

  deactivated() {
    this.showMap = false;
  },

  beforeDestroy() {
    this.$refs.map.removeEventListener('click', e => this.routeToInfo(e));
  },

  methods: {
    operateFavorite(id, title) {
      this.onEvent = true;
      const api = new Api();
      api.setFavorite(this.currentMarkerId?.split('_')[0], title);
      this.favoriteIds[this.currentMarkerId?.split('_')[0]] = !this.favoriteIds[this.currentMarkerId?.split('_')[0]];
      this.count++;
    },
    operateFavoriteCard(id, title) {
      console.log(title);
      const api = new Api();
      api.setFavorite(id, title);
      this.favoriteIds[id] = !this.favoriteIds[id];
      this.count++;
    },
    countArrays(array) {
      let sumTotal = 1;

      for (const element of array) {
        if (Array.isArray(element)) {
          sumTotal += this.countArrays(element);
        }
      }

      return sumTotal;
    },
    async searchAddress() {
      if (!this.searchText) return;
      const api = new Api();

      const data = {
        address: this.searchText
      };

      // this.addressLoading = true;

      const res = await api.getAddress(JSON.stringify(data));

      if (res?.lat && res?.lng) {
        this.personAddressCoords = [res.lat, res.lng];
        this.personAddressHint = res?.address || '';
      }

      // this.addressLoading = false;
    },
    iconBalloon(link) {
      if (this.favoriteIds[this.currentMarkerId?.split('_')[0]]) {
        link.innerHTML = `<svg width="16" class="ml-auto pointer" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3.01448C2 1.34963 3.34315 0 5
                            0H11C12.6569 0 14 1.34963 14 3.01448V13.9866C14 15.6717
                            12.0601 16.6085 10.7506 15.5558L8 13.3447L5.24939 15.5558C3.93986 16.6085 2 15.6717 2 13.9866V3.01448Z" fill="#3D75E4"/>
                          </svg>`;
      } else {
        link.innerHTML = `<svg width="16" class="ml-auto pointer" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 2.00966C4.44772 2.00966 4 2.45953
                            4 3.01448V13.9866L6.75061 11.7755C7.48105 11.1883
                            8.51895 11.1883 9.24939 11.7755L12 13.9866V3.01448C12 2.45953 11.5523 2.00966 11
                            2.00966H5ZM2 3.01448C2 1.34963 3.34315 0 5 0H11C12.6569
                            0 14 1.34963 14 3.01448V13.9866C14 15.6717 12.0601 16.6085 10.7506 15.5558L8
                            13.3447L5.24939 15.5558C3.93986 16.6085 2 15.6717 2 13.9866V3.01448Z" fill="#3D75E4"/>
                          </svg>`;
      }
    },
    routeToInfo(e) {
      if (!this.onEvent) {
        if (e.target.closest('.r-map-balloon__info')) {
          this.$router.push({
            name: 'info',
            query: {
              id: this.currentMarkerId?.split('_')[0],
              type: this.selectedCategory === 0 ? 'institution' : 'district'
            }
          });
        }
      }
      this.onEvent = false;
      this.favoriteCount++;
    },
    onMarkerClick(e) {
      this.currentMarkerId = e.originalEvent.target.properties._data.markerId;
    },
    onBalloonOpen(event, id, value, title) {
      this.selectedMarkerTitle = title;
      this.eventId = event;
      const link = this.$refs.map.querySelector(
        `.link-balloon[link-id="${this.currentMarkerId?.split('_')[0]}"]`
      );
      this.iconBalloon(link);
      link.addEventListener('click', this.operateFavorite);
      this.$refs.map.addEventListener('click', this.routeToInfo);
    },
    onBalloonClose() {
      this.$refs.map.removeEventListener('click', this.routeToInfo);
    },

    getPreviewImg(item) {
      if (this.selectedCategory === 0) return item.img;
      return item?.clinics?.[0]?.img ? item.clinics[0].img : null;
    },

    getStaffBaloon(ids) {
      const staff = [];
      if (ids?.length > 0) {
        ids.forEach(id => {
          const findStaff = this.$store.getters.getStaffById(id);
          findStaff && staff.push(findStaff);
        });
      }
      return staff;
    },

    loadObjects(typeId) {
      this.$store.dispatch('loadObjects', typeId);
    },

    onChangeType(type) {
      if (type.id !== this.selectedCategory) {
        this.selectedCategory = type.id;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.medicine {
  display: flex;
  justify-content: space-between;
  &__cards {
    width: 41.7%;
  }
  &__map-cont {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    height: 648px;
    width: calc(58.3% - 32px);
    margin-left: 32px;
  }
  &__map {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    position: relative;
    transition: 0.4s all;
  }
  &__map-collapse-btn {
    display: none;
  }
}

.balloon-staff {
  display: grid;
  grid-template-columns: auto 1fr;
}

@media screen and (max-width: 1023px) {
  .medicine {
    max-width: 100%;
    flex-direction: column-reverse;
    &__cards {
      width: 100%;
    }
    &__map-cont {
      position: relative;
      top: unset;
      background-color: #fff;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }
    &__map {
      height: 360px;
      margin-bottom: 44px;
    }
    &__map--collapsed {
      height: 112px;
    }
    &__map-collapse-btn {
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;
      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dotted #214eb052;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .medicine {
    &__input-search {
      display: none;
      // position: absolute;
      // z-index: 10;
      // background-color: #fff;
      // box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
      // top: 16px;
      // left: 16px;
    }
  }
}

@media screen and (max-width: 599px) {
  .medicine {
    &__map--collapsed {
      height: 144px;
    }
  }
}
.size {
  width: 94%;
}
</style>
