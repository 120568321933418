import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';

const api = new Api();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    types: null,
    objects: null,
    districts: [],
    clinics: [],
    staff: [],
    selectedObject: null,
    isObjectLoading: null,
    isHasObjects: false
  },
  mutations: {
    // setTypes(state, types) {
    //   state.types = types;
    // },
    setObjects(state, objects) {
      state.objects = objects;
    },
    setDistricts(state, data) {
      state.districts = data;
    },
    setClinics(state, data) {
      state.clinics = data;
    },
    setStaff(state, data) {
      state.staff = data;
    },
    setSelectedObject(state, object) {
      state.selectedObject = object;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
      status && (state.isHasObjects = true);
    }
  },
  getters: {
    // getTypes: (state) => {
    //   return state.objects.types;
    // },
    // getDocTypes: (state) => {
    //   return state.types?.doctorTypes || [];
    // },
    getInstitutions: state => state.objects.types.clinics,
    getInstitutByType: state => type => state.clinics.filter(el => String(el.type) === String(type)),
    // getStaffByType: (state) => (type) => {
    //   return state.staff.filter((el) => String(el.type) === String(type));
    // },
    // getObjectById: (state) => (id) => {
    //   return state.objects.find((el) => el.id === id);
    // },
    getStaffById: state => id => state.staff.find(el => String(el.id) === String(id)),
    getDistrictById: state => id => state.districts.find(el => String(el.id) === String(id)),
    getInstitutionById: state => id => state.clinics.find(el => String(el.id) === String(id))
  },
  actions: {
    // async selectObject(ctx, id) {
    //   if (!ctx.state.objects?.length) {
    //     const res = await api.getList({ readOnly: 1, type: 0, currentMainMenuNumber: 5 });
    //     ctx.commit('setObjects', res.all);
    //   }
    //   const selected = ctx.state.objects.find((el) => el.id === id);
    //   ctx.commit('setSelectedObject', selected);
    // },
    async loadObjects({ dispatch, commit }, typeId = 0) {
      commit('setObjectLoading', true);

      // const res = await api.getTypes();
      // console.log('types', res);
      // commit('setTypes', res);

      const districts = await api.getDoctorHouses({ readOnly: 1 });
      commit('setDistricts', districts.all);

      const clinics = await api.getClinics({ readOnly: 1 });
      commit('setClinics', clinics.all);

      const stuff = await api.getStaff({ readOnly: 1 });
      commit('setStaff', stuff.all);

      commit('setObjectLoading', false);
    }

    // async getClinics({ commit }) {
    //   const clinics = await api.getClinics({ readOnly: 1 });
    //   commit('setClinics', clinics.all);
    //   console.log('clinics', clinics);
    // },
    // async getDistricts({ commit }) {
    //   const districts = await api.getDoctorHouses({ readOnly: 1 });
    //   commit('setDistricts', districts.all);
    //   console.log('districts', districts);
    // },
    // async getStaff({ commit }) {
    //   const res = await api.getStaff({ readOnly: 1 });
    //   commit('setStaff', res.all);
    // },
    // async loadTypes(ctx) {
    //   const res = await api.getTypes();
    //   console.log('types', res);
    //   ctx.commit('setTypes', res);
    // },
    // async setStaff(ctx, data) {
    //   const res = await api.setStaff({ item: data });
    //   console.log('addStaff', res);
    // },
    // async setDistrict(ctx, data) {
    //   const res = await api.setDistrict({ item: data });
    //   console.log('addDistrict', res);
    // },
    // async setClinic(ctx, data) {
    //   const res = await api.setClinic({ item: data });
    //   console.log('addClinic', res);
    // },
  },
  modules: {}
});
