<template>
  <div class="m-image-gall">
    <r-gallery
      :files="items"
      :show-zoom="false"
      :show-title="true"
      :open-modal="false"
      :index="index"
    />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number
    }
  }
};
</script>
<style lang="scss" scoped>
.m-image-gall {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-direction: column; */
  /* height: auto; */
  padding-top: 50%;
  min-width: min(75vw, 774px);
}

@media (max-width: 599px) {
  .m-image-gall {
    min-width: 90vw;
  }
}

@supports not (aspect-ratio: 16/9) {
  ::v-deep .rir-gallery {
    height: 34vh;
    padding-top: 50%;
  }
}
</style>
