import Vue from 'vue';
import VueRouter from 'vue-router';
import Medicine from '../views/Medicine.vue';
import ObjectInfo from '../views/ObjectInfo.vue';
import ObjectList from '../views/ObjectList.vue';
import Page404 from '../views/Page404';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Medicine',
    component: Medicine,
    children: [
      {
        path: '',
        name: 'list',
        component: ObjectList
      },
      {
        path: 'info',
        name: 'info',
        component: ObjectInfo
      },
      { path: '*', name: 'Page404', component: Page404 }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
