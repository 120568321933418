import ApiService from './ApiService';

export default class Api extends ApiService {
  // - справочник типов клиник, ФИО для дропдауна, координаты для карты
  async getTypes(payload) {
    const { data } = await this._axios.post('ajax.php?action=getDoctors', {
      readOnly: 1
    });
    return data;
  }

  // $.postJSON("/ajax.php?action=getDoctorHouses|getDoctorDistricts|getDoctorStaff|getDoctorClinics",{readOnly:1,type:type})
  // - квартирные адреса по участкам
  // - геометрия
  // - врачи
  // - клиники
  async getFavorite(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateFavorite', {
      currentMainMenuNumber: 6
    });
    return data;
  }

  async setFavorite(id, title) {
    const { data } = await this._axios.post('ajax.php?action=operateFavorite', {
      currentMainMenuNumber: 6,
      id,
      title
    });
    return data;
  }

  async getDoctorHouses(payload) {
    const { data } = await this._axios.post('ajax.php?action=getDoctorDistricts', payload);
    return data;
  }

  async getStaff(payload) {
    const { data } = await this._axios.post('ajax.php?action=getDoctorStaff', payload);
    return data;
  }

  async getClinics(payload) {
    const { data } = await this._axios.post('ajax.php?action=getDoctorClinics', payload);
    return data;
  }

  async getTransport(payload) {
    const { lat, lng } = payload;
    const { data } = await this._axios.get(
      `transportProxy.php?url=stations&lat=${lat}&lng=${lng}&radius=500`
    );
    return data;
  }

  async setRating(payload) {
    const { data } = await this._axios.post('ajax.php?action=operatePublicRating', payload);
    return data;
  }

  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('mob_ajax.php?action=geosearch', payload);
    return data;
  }
}
